<template>
  <div class="season-total-point">
    <div class="main">
      <div class="header">
        <div class="left">
          <div class="title">賽季排行榜</div>
          <div class="sub-title upper">Season leaderboard</div>
        </div>
      </div>
      <div class="teams">
        <div v-for="(item,idx) in teams" :key="idx" class="team">
          <div class="sort" v-html="getRankStr(idx+1)" />
          <div class="team-label" :style="'background-color:'+$dataManager.findTeam(item.id).team.color" v-text="$dataManager.findTeam(item.id).team.displayName" />
          <div class="team-point" :style="'color:'+$dataManager.findTeam(item.id).team.color">
            <span>Team <br>Points</span>
          </div>
          <div class="team-score" :style="'color:'+$dataManager.findTeam(item.id).team.color">
            <span v-text="$dataManager.findTeam(item.id).team.teamScore" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SeasonTotalPoint',
  data() {
    return {
      season: {},
      teams: {},
      isLoading: false,
      liveSetting: {}
    }
  },
  computed: {
    title: function() {
      return this.season.name ? this.season.name.indexOf('N') !== -1 ? 'N' : 'S' : ''
    },
    fontSize: function() {
      var domain = 'www.supermahjongleague.com'
      var width = window.innerWidth
      const fontSize = ((width - 80) / (1 + Math.max((domain).split('\n').map(t => t.length))))
      return 'font-size:' + fontSize + 'px;flex:0 ' + fontSize
    }
  },
  mounted() {
    this.$bus.$on('liveSetting', content => {
      this.liveSetting = content
      this.getData()
    })
  },
  methods: {
    getRankStr: function(idx) {
      var data = ''
      switch (idx) {
        case 1:
          data = '<span>1</span><span>st</span>'
          break
        case 2:
          data = '<span>2</span><span>nd</span>'
          break
        case 3:
          data = '<span>3</span><span>rd</span>'
          break
        case 4:
          data = '<span>4</span><span>th</span>'
          break
        case 5:
          data = '<span>5</span><span>th</span>'
          break
        case 6:
          data = '<span>6</span><span>th</span>'
          break
        case 7:
          data = '<span>7</span><span>th</span>'
          break
        case 8:
          data = '<span>8</span><span>th</span>'
          break
      }
      return data
    },
    getData: function() {
      this.$store.commit('CLOSE_LOADING')
      this.$store.dispatch('getData', { type: 'Season', credentials: this.liveSetting.seasonId }).then((res) => {
        if (!res.errorCode) {
          this.season = res.data
          this.teams = res.data.teams
          this.teams.sort(function(a, b) {
            return a.teamRank - b.teamRank
          })
          this.$store.commit('UPDATE_TEAM', res.data.teams)
          this.isLoading = true
        }
      })
    }
  }
}
</script>

<style lang="scss">
  @import '../../../style/show/seasonTotalPoint';
</style>
