<template>
  <!-- eslint-disable vue/no-use-v-if-with-v-for,vue/no-confusing-v-for-v-if -->
  <div class="card card-user">
    <div class="card-body">
      <el-row :gutter="5">
        <el-col :span="12">
          <el-tag v-text="'['+season.gType + season.TypeText + ']'" />
          <h3 style="width:100%" v-text="season.name" />
        </el-col>
        <el-col :span="12">
          <el-button-group>
            <el-button v-if="permissions.Live.includes('create') && season.id" plain type="danger" icon="el-icon-plus" @click="$router.push({name:'Live.Create',params:{seasonId:season.id}})">
              建立直播
            </el-button>
            <el-button v-if="permissions.Season.includes('update')" plain type="success" icon="el-icon-edit" @click="$router.push({name:'Season.Update',params:{id:season.id}})">
              編輯賽季
            </el-button>
            <el-button plain type="info" icon="el-icon-copy-document" @click="copy = !copy">複製賽季</el-button>
          </el-button-group></el-col>
      </el-row>

      <div v-if="copy" class="form-group row">
        <label class="col-md-2 col-form-label">名稱</label>
        <div class="col-md-6">
          <input v-model="newName" type="text" class="form-control">
        </div>
        <div class="col-md-4">
          <div class="btn btn-primary" style="margin: 0;" @click="submit">複製</div>
        </div>
      </div>
      <p v-if="season.type ==3" class="text-primary">例行賽積分</p>
      <table v-if="season.type ==3" class="table">
        <tr>
          <td>名次</td>
          <td>加分</td>
        </tr>
        <tr v-for="(s,i) in season.setting" :key="i">
          <td v-text="getRank(i)" />
          <td v-text="s" />
        </tr>
      </table>

      <el-row :gutter="20">
        <el-col :span="22">
          <h5>隊伍列表</h5>
        </el-col>
        <el-col :span="2">
          <el-button v-if="permissions.Team.includes('create')" icon="el-icon-plus" circle type="success" @click="$router.push({name:'Team.Create',params:{seasonId:id}})" /></el-col>
      </el-row>
      <el-table v-loading="seasonLoading" :data="showTeamsArr" style="width: 100%">
        <el-table-column prop="scope" label="隊伍名稱">
          <template slot-scope="scope">
            <router-link
              v-if="permissions.Team.includes('list')"
              :to="{name:'Team.Detail',params:{id:scope.row.id}}"
            >
              <span v-text="scope.row.displayName" />
            </router-link>
          </template>
        </el-table-column>
        <el-table-column prop="scope" label="選手">
          <template slot-scope="scope">
            <div
              v-for="(player,i) in scope.row.players"
              v-if="permissions.Player.includes('list')"
              :key="i"
            >
              <router-link
                target="_blank"
                :to="{name:'Show.PlayerList',params:{seasonId:id,id:player.id}}"
              >
                <span v-text="player.displayName" />
              </router-link>
              <el-button
                v-if="permissions.Player.includes('update') && PlayerEdit.id == ''"
                icon="el-icon-edit"
                size="mini"
                style="margin-left: 5px;"
                @click="handleEditPlayer(player.id,player.name,player.displayName)"
              />
              <div v-if="PlayerEdit.id == player.id" class="form-group row">
                <label class="col-md-2 col-form-label">編輯名稱</label>
                <div class="col-md-6">
                  <input v-model="PlayerEdit.editName" type="text" class="form-control">
                </div>
                <div class="col-md-4">
                  <div class="btn btn-primary" style="margin: 0;" @click="submitUpdatePlayer">送出</div>
                </div>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="scope" label="ICON">
          <template slot-scope="scope">
            <div v-html="makeIcon(scope.row)" />
          </template>
        </el-table-column>
      </el-table>
      <el-row :gutter="5">
        <el-col :span="6" :offset="18">
          <el-pagination
            :current-page="teamsCurrentPage"
            :page-size="teamsPageSize"
            :hide-on-single-page="true"
            background
            small
            layout="total, pager"
            :total="season.teams.length"
            @next-click="handleTeamsCurrentChange"
            @prev-click="handleTeamsCurrentChange"
            @current-change="handleTeamsCurrentChange"
          />
        </el-col>
      </el-row>

      <h5>直播列表</h5>
      <el-table v-loading="liveLoading" :data="showLivesArr" style="width: 100%">
        <el-table-column prop="name" label="名稱" />
        <el-table-column prop="startDatetime" label="建立時間" />
        <el-table-column prop="scope" label="詳情" width="75">
          <template slot-scope="scope">
            <el-button icon="el-icon-more" circle type="success" @click="$router.push({name:'Live.Detail',params:{sid:id,id:scope.row.id}})" />
          </template>
        </el-table-column>
        <el-table-column v-if="permissions.Live.includes('delete')" prop="scope" label="刪除" width="75">
          <template slot-scope="scope">
            <el-button icon="el-icon-delete" circle type="danger" @click="deleteItem(scope.row.id)" />
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        :current-page="liveCurrentPage"
        :page-size="livePageSize"
        :hide-on-single-page="true"
        background
        small
        layout="total, pager"
        :total="liveshows.length"
        @next-click="handleLiveCurrentChange"
        @prev-click="handleLiveCurrentChange"
        @current-change="handleLiveCurrentChange"
      />

    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'Detail',
  data: function() {
    return {
      seasonLoading: true,
      liveLoading: true,
      id: this.$route.params.id,
      teamsPageSize: 6,
      teamsCurrentPage: 1,
      livePageSize: 10,
      liveCurrentPage: 1,
      gameType: [],
      liveshows: [],
      copy: false,
      newName: '',
      season: {
        gtId: '',
        gType: '',
        TypeText: '',
        name: '',
        type: 1,
        teams: [],
        setting: {
          rankingFirst: 0,
          rankingSecond: 0,
          rankingThird: 0,
          rankingFourth: 0
        }
      },
      PlayerEdit: {
        id: '',
        name: '',
        displayName: '',
        editName: ''
      },
      type: [
        { key: 1, name: '日間直播' },
        { key: 2, name: '一般比賽' },
        { key: 3, name: '大型季賽' }
      ]
    }
  },
  computed: {
    ...mapGetters([
      'permissions'
    ]),
    showTeamsArr: function() {
      let arr = []
      if (this.season.teams) {
        if (this.season.teams.length) {
          arr = this.season.teams.slice(
            (this.teamsCurrentPage - 1) * this.teamsPageSize,
            this.teamsCurrentPage * this.teamsPageSize
          )
        }
      }
      return arr
    },
    showLivesArr: function() {
      let arr = []
      if (this.liveshows.length) {
        arr = this.liveshows.slice(
          (this.liveCurrentPage - 1) * this.livePageSize,
          this.liveCurrentPage * this.livePageSize
        )
      }
      return arr
    }
  },
  mounted() {
    this.getData()
  },
  methods: {
    makeIcon: function(team) {
      var str = ''
      // if(team.icon.length>0){
      //   if (team.icon.indexOf('fill="none"') !== -1) {
      //     str= team.icon.replace('fill="none"', 'fill="' + team.color + '"')
      //   } else {
      str = team.icon
      //   }
      // }
      return str
    },
    getData: function() {
      this.seasonLoading = true

      var $this = this
      this.$store.dispatch('getList', { type: 'GameType', action: 'list' }).then((res) => {
        if (!res.errorCode) {
          this.gameType = res.data
          this.$store.dispatch('getList', { type: 'LiveShow', action: 'list', param: this.id }).then((res) => {
            if (!res.errorCode) {
              this.liveshows = res.data
              this.liveLoading = false

              this.$store.dispatch('getData', { type: 'Season', credentials: this.id }).then((res) => {
                if (!res.errorCode) {
                  this.season = res.data
                  this.seasonLoading = false

                  this.gameType.forEach((gitem) => {
                    if (gitem.id === $this.season.gtId) {
                      $this.season.gType = gitem.name
                    }
                  })
                  this.type.forEach((item) => {
                    if (item.key === $this.season.type) {
                      $this.season.TypeText = item.name
                    }
                  })
                }
              })
            }
          })
        }
      })
    },
    deleteItem: function(id) {
      this.liveshows.forEach((item, index) => {
        if (item.id === id) {
          this.liveshows.splice(index, 1)
        }
      })
      this.$store.dispatch('liveDelete', { credentials: id })
    },
    getRank: function(rank) {
      var data = ''
      switch (rank) {
        case 'rankingFirst':
          data = '第一名'
          break
        case 'rankingSecond':
          data = '第二名'
          break
        case 'rankingThird':
          data = '第三名'
          break
        case 'rankingFourth':
          data = '第四名'
          break
      }
      return data
    },
    submit: function() {
      var data = {
        id: this.season.id,
        name: this.newName
      }
      this.$store.dispatch('seasonCopy', { credentials: data }).then((res) => {
        if (!res.errorCode) {
          this.$store.commit('CLOSE_LOADING')
          this.$router.push({ name: 'Season.List' })
        }
      })
    },
    handleEditPlayer: function(id, name, displayName) {
      this.PlayerEdit.id = id
      this.PlayerEdit.name = name
      this.PlayerEdit.displayName = displayName
    },
    submitUpdatePlayer: function() {
      var data = {
        id: this.PlayerEdit.id,
        player: {
          name: this.PlayerEdit.editName,
          displayName: this.PlayerEdit.editName
        }
      }
      this.$store.dispatch('playerUpdate', { credentials: data }).then((res) => {
        if (!res.errorCode) {
          this.$store.commit('CLOSE_LOADING')
          this.PlayerEdit.id = ''
          this.PlayerEdit.name = ''
          this.PlayerEdit.displayName = ''
          this.PlayerEdit.editName = ''
          this.getData()
        }
      })
    },
    handleTeamsCurrentChange(val) {
      this.teamsCurrentPage = val
    },
    handleLiveCurrentChange(val) {
      this.liveCurrentPage = val
    }
  }
}
</script>

<style scoped>

</style>
